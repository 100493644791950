.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heightLimited {
  height: 100vh;
  overflow: hidden;
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .heightLimited {
    height: auto;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .heightLimited {
    height: auto;
  }
}

.containerAnimated {
  filter: opacity(.75) blur(10px);
}

.main {
  width: 100%;
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .main {
    padding: 1rem 0;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .main {
    padding: 1rem 0;
  }
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  text-decoration: none;
}

.title a.nextjs {
  color: #0070f3 !important;
}

.title strong {
  color: var(--flex-gateway-highlight, #f9423a) !important;
}

.title a:after {
  height: 0.1em !important;
}

@media screen and (max-width: 1023px) {
  .title{
    margin-bottom: 2rem !important;
  }
  .title a {
    padding: 0 !important;
    border-bottom: solid 0.45rem currentColor !important;
  }
}

/*
.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}
*/

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.flexinessTitle {
  font-size: 7rem !important;
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .isFlexMobile .flexinessTitle {
    font-size: 5rem !important;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .isFlexMobile .flexinessTitle {
    font-size: 6rem !important;
  }
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
  white-space: break-spaces;
}

.description.subHeader {
  /*
  line-height: 1.25;
  font-size: 1.25rem;
  */
  font-weight: bold;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.flexSection {
  padding: 2.025rem 0 !important;
  max-width: 100%;
}
/*
@media screen and (orientation: portrait) and (max-width: 500px) {
  .isFlexMobile .flexSection {
    padding: 2.025rem 0;
  }
}
@media screen and (orientation: landscape) and (max-width: 900px) {
  .isFlexMobile .flexSection {
    padding: 2.025rem 0;
  }
}
@media screen and (min-width: 768px) {
  .flexSection {
    padding: 2.025rem 0;
  }
}
@media screen and (min-width: 900px) and (max-width: 1179px) {
  .flexSection {
    padding: 2.025rem 0;
  }
}
*/
@media screen and (min-width: 1180px) {
  .flexSection {
    padding: 2.025rem 2.7rem !important;
    /* max-width: 90%; */
  }
}

.grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  justify-content: stretch;
  justify-items: stretch;
  max-width: 960px;
  margin: 0 auto;
}

.grid.fullWidth {
  max-width: 100%;
}

.slider {
  grid-column: span 1 / span 1;
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .isFlexMobile .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .isFlexMobile .slider {
    grid-column: span 1 / span 1;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .isFlexMobile .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .isFlexMobile .slider {
    grid-column: span 2 / span 2;
  }
}

@media screen and (min-width: 499px) {
  .slider {
    grid-column: span 2 / span 2;
  }
}

@media screen and (min-width: 540px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.heroBox {
  overflow: hidden;
}

.heroBox .homeContainer {
  margin: -3rem 0 -3rem !important;
}

.heroBox .homeBytelLogoHolder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  padding-top: 2rem;
}

.heroBox .homeBytelLogoHolder #homeBytelLogo {
  height: 175px;
  fill: #fff;
}

@media screen and (min-width: 1024px) {
  .heroBox .homeContainer {
    margin: -4rem 0 -4rem !important;
  }

  .heroBox .homeBytelLogoHolder {
    align-items: center;
    justify-content: flex-start;
    margin-left: -45px;
    height: 180px;
    padding-top: 0;
  }

  .heroBox .homeBytelLogoHolder #homeBytelLogo {
    height: 225px;
  }
}

.card {
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color .15s ease, border-color .15s ease;
  width: auto;
  height: 100%;
}

.card.werewolf {
  padding: 0.25rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: none;
  width: auto;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
  /* background-size: 100%; */
  /* background-image: linear-gradient(-228deg, #f9423a, #25465f, #109db9, #f9423a, #25465f, #109db9); */
}

.card.werewolf.highlight:before,
.card.werewolf.highlightHover:before {
  content: '';
  box-shadow: inset 0 0 0 10px transparent;
  border-radius: inherit;
  background:
    linear-gradient(0deg, #f9423a, #25465f, #109db9, #25465f, #f9423a, #109db9);
  display: block;
  height: 200%;
  width: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
  opacity: 0;
  z-index: 1;
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}

.card.werewolf.highlight.One:before {
  transform-origin: center center;
  height: 325%;
  width: 175%;
  top: -106%;
  left: -37%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.card.werewolf .content {
  z-index:2;
}

.slider .card.werewolf .content {
  height: 710px;
}

.card.werewolf.highlight .content {
  position: relative;
  padding: 1rem;
  border-radius: 7px;
  background-color: #fff;
  height: 100%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card.werewolf:hover,
.card.werewolf:focus,
.card.werewolf:active, {
  color: inherit;
  border-color: none;
}

.card.werewolf.highlight:before {
  opacity: 1;
}

.card.werewolf.highlight:hover .content,
.card.werewolf.highlight:focus .content
.card.werewolf.highlight:active .content {
  background-color: #25465f;
  color: #fff;
}

.card.werewolf.highlightHover:hover:before,
.card.werewolf.highlightHover:focus:before,
.card.werewolf.highlightHover:active:before {
  opacity: 1;
}

.slider .card.werewolf .content .cardImage,
.slider .card.werewolf .content .cardContent {
  width: 100%;
}

.slider .card.werewolf .content .cardImage {
  max-height: 300px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 14px;
}

.slider .card.werewolf .content .cardProgress {
  transform-origin: top left;
  transform: rotate(90deg);
  position: relative;
  top: 0;
  left: 14px;
  height: 10px;
  width: 400px;
}

.slider .card.werewolf .content .cardProgress .progressHome {
  background-color: var(--flex-info, #109db9);
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .isFlexMobile .slider .card.werewolf .content {
    height: 635px;
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage {
    max-height: 200px;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .isFlexMobile .slider .card.werewolf .content {
    height: 325px;
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage {
    max-height: 300px;
    padding-right: 0;
    overflow: visible;
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage.isOneThird {
    width: 100%;
    max-width: calc(33% + 10px);
    max-height: 100%;
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage.isOneThird + .cardContent {
    max-width: calc(66% - 10px);
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage.isTwoSixths {
    width: 100%;
    max-width: calc(40% + 10px);
    max-height: 100%;
  }

  .isFlexMobile .slider .card.werewolf .content .cardImage.isTwoSixths + .cardContent {
    max-width: calc(60% - 10px);
  }

  .isFlexMobile .slider .card.werewolf .content .cardProgress {
    width: 325px;
  }

  .isFlexMobile .slider .card.werewolf .content .cardProgress .progressHome {
    transform: scaleX(1.15);
    translate: 25px 0;
  }
}

@media screen and (min-width: 768px) {
  .slider .card.werewolf .content {
    height: 415px;
  }

  .slider .card.werewolf .content .cardImage {
    max-height: 100%;
    padding-right: 0;
    overflow: visible;
  }
  
  .slider .card.werewolf .content .cardImage.isOneThird {
    width: 100%;
    max-width: calc(33% + 10px);
    max-height: 100%;
  }

  .slider .card.werewolf .content .cardImage.isOneThird + .cardContent {
    max-width: calc(66% - 10px);
  }

  .slider .card.werewolf .content .cardImage.isTwoSixths {
    width: 100%;
    max-width: calc(40% + 10px);
    max-height: 100%;
  }

  .slider .card.werewolf .content .cardImage.isTwoSixths + .cardContent {
    max-width: calc(60% - 10px);
  }

  .slider .card.werewolf .content .cardProgress {
    width: 415px;
  }

  .slider .card.werewolf .content .cardProgress .progressHome {
    background-color: var(--flex-progress-bar-background-color, #eee);
  }
}

@media screen and (min-width: 900px) and (max-width: 1179px) {
  .slider .card.werewolf .content {
    height: 450px;
  }
  
  .slider .card.werewolf .content .cardProgress {
    width: 450px;
  }
}

@media screen and (min-width: 1180px) {
  .slider .card.werewolf .content {
    height: 475px;
  }
  
  .slider .card.werewolf .content .cardProgress {
    width: 475px;
  }
}

.slider .card.werewolf .content .slideTitle {
  word-wrap: break-word;
}

.isFlexMobileslider .card.werewolf .content .cardParagraph {
  word-break: break-word;
}

/* @container card-werewolf-content (min-width: 1090px) {
  .slider .card.werewolf .content .slideTitle {
    font-size: 2.16em !important;
  }
} */

@media screen and (orientation: portrait) and (max-width: 500px) {
  .isFlexMobile .slider .card.werewolf .content .suptitle {
    font-size: 0.81em !important;
  }

  .isFlexMobile .slider .card.werewolf .content .slideTitle {
    font-size: 1.6em !important;
  }

  .isFlexMobileslider .card.werewolf .content .cardParagraph {
    /* font-size: 1.6em !important; */
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .isFlexMobile .slider .card.werewolf .content .suptitle {
    font-size: 0.81em !important;
  }

  .isFlexMobile .slider .card.werewolf .content .slideTitle {
    font-size: 1.6em !important;
  }

  .isFlexMobile .slider .card.werewolf .content .cardParagraph {
    /* font-size: 1.6em !important; */
  }
}

@media screen and (min-width: 768px) {
  .slider .card.werewolf .content .suptitle {
    font-size: 1.215em !important;
  }

  .slider .card.werewolf .content .slideTitle {
    font-size: 1.9em !important;
  }

  .slider .card.werewolf .content .cardParagraph {
    /* font-size: 1.6em !important; */
  }
}

@media screen and (min-width: 900px) and (max-width: 1179px) {
  .slider .card.werewolf .content .suptitle {
    font-size: 0.81em !important;
  }

  .slider .card.werewolf .content .slideTitle {
    font-size: 1.6em !important;
  }

  .slider .card.werewolf .content .cardParagraph {
    /* font-size: 1.6em !important; */
  }

  .slider .card.werewolf .content .cardParagraphEllipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1180px) {
  .slider .card.werewolf .content .suptitle {
    font-size: 1.215em !important;
  }

  .slider .card.werewolf .content .slideTitle {
    font-size: 2.16em !important;
  }

  .slider .card.werewolf .content .cardParagraph {
    /* font-size: 1.6em !important; */
    font-size: 1.25rem;
  }

}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card:not(.werewolf) p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.fadeTransitionSlides {
  animation: fade-transition-dissolve 2s;
}

@keyframes fade-transition-dissolve {
  from { opacity: 0; }
  to { opacity: 1; }
}

/*
.navbarTogglerIcon {
	display: inline-block;
  width: 2.5em;
  height: 2.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: cover;
}

.slidesMenu .navbarTogglerIcon.strokeGrey,
.slidesMenu.closed .navbarTogglerIcon.strokeGrey {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(69, 69, 69, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.slidesMenu .navbarTogglerIcon.strokeGreyInvert,
.slidesMenu.closed .navbarTogglerIcon.strokeGreyInvert {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(250, 250, 250, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
*/

.slidesMenuOpen .slidesTogglerClose {
  width: 2em;
  height: 2em;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}
.slidesMenuOpen .slidesTogglerClose span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  /* background: var(--flex-gateway-strokeGrey, #283448); */
  background: #fff;
}
.slidesMenuOpen .slidesTogglerClose :nth-child(1) {
  transform: rotate(135deg);
}
.slidesMenuOpen .slidesTogglerClose :nth-child(2) {
  transform: rotate(-135deg);
}

.slidesMenuOpen {
  position: fixed;
  /*
  top: 0.75rem;
  right: 0.75rem;
  */
  top: 1.15rem;
  right: 1.15rem;
  z-index: 3;
}

.slidesMenuOpen .showSlideBtn {
  display: block;
  position: absolute;
  width: 17rem;
  right: 0;
  top: 0.5rem;
  margin: 0 auto;
  font-size: 1.7rem;
}

.slidesMenuOpen .showSlideBtn .showSlideBtnTxt {
  display: inline;
}

@media screen and (max-width: 900px) {
  .slidesMenuOpen .showSlideBtn {
    display: block;
    position: fixed;
    width: 300px;
    left: 7rem;
    top: 2rem;
    margin: 0 auto;
    font-size: 2rem;
  }

  .slidesMenuOpen .showSlideBtn .showSlideBtnTxt {
    display: inline;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .slidesMenuOpen .showSlideBtn {
    display: block;
    position: fixed;
    width: 100px;
    left: 7rem;
    top: 2rem;
    margin: 0 auto;
    font-size: 2rem;
  }

  .slidesMenuOpen .showSlideBtn .showSlideBtnTxt {
    display: none;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .slidesMenuOpen .showSlideBtn {
    display: block;
    position: fixed;
    width: 200px;
    left: 0;
    top: 2.5rem;
    margin: 0 auto;
    font-size: 1.5rem;
  }
  .slidesMenuOpen .showSlideBtn .showSlideBtnTxt {
    display: inline;
  }
}

.slidesMenuClosed {
  position: absolute;
  top: 2.1rem;
  /* logoBadge : 125px */
  /* navbar-toggler-icon : 2.5em */
  width: calc(100vw - 125px - 2.5em - 6rem);
  left: calc(125px + 2.5rem);
  /*
  width: 300px;
  left: 50%;
  margin-left: -150px;
  */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  /* border: 1px solid red; */
}

@media screen and (min-width: 900px) {
  .slidesMenuClosed {
    position: absolute;
    display: block;
    width: auto;
    top: 1.15rem;
    right: 1.15rem;
    left: auto;
    z-index: 3;
  }
}

.logoBadge:before {
  position: relative;
  content: "";
  display: flex;
  width: 100px;
  height: 55px;
  left: 50%;
  margin-left: -50px;
  /* background-image: url("/logo/transparent/rectangle/logo_flexiness_2.svg"); */
  /* background-image: url("/logo/transparent/rectangle/logo_flexiness_2_inverse.svg"); */
  background-image: url("/logo/filled/rectangle/logo_flexiness_2.svg");
  /* background-image: url("/logo/filled/rectangle/logo_flexiness_2_inverse.svg"); */
  background-size: 90%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
